import React, { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import { connect } from "react-redux";
import PrivateRoute from "../PrivateRoute/PrivateRoute";
import RoleList from "../../../pages/UserRole/Components/Role/RoleList/RoleList";
import PermissionsList from "../../../pages/UserRole/Components/Permission/Permissions";

const HelloPage = lazy(() => import("../../../components/sharedComponents/HelloPage"))
const ErrorPage = lazy(() => import("../../../components/sharedComponents/ErrorPage"))
const Creator = lazy(() => import("../../../pages/Creator/components/Creator"));
const CourseDashboard = lazy(() =>
  import("../../../pages/Courses/components/CourseDashboard")
);
const CoursesList = lazy(() =>
  import("../../../pages/Courses/components/CoursesList")
);
const CourseStudents = lazy(() =>
  import("../../../pages/Courses/components/CourseStudents")
);
const CourseEntrollments = lazy(() =>
  import("../../../pages/Courses/components/CourseEntrollments")
);
const CourseSettings = lazy(() =>
  import("../../../pages/Courses/components/CourseSettings")
);
const CourseStudio = lazy(() =>
  import("../../../pages/Courses/components/CourseStudio")
);
const CreateCourse = lazy(() =>
  import("../../../pages/Courses/components/CreateCourse")
);
const LessonPreview = lazy(() =>
  import(
    "../../../pages/Courses/components/CourseStudio/PreviewPages/LessonPreview"
  )
);
// User Course Preview
const UserCourseDashboard = lazy(() =>
  import("../../../pages/Courses/components/UserCourse/UserCourseDashboard")
);

const CourseDetails = lazy(() =>
  import("../../../pages/Courses/components/UserCourse/CourseDetails")
);
const CoursePreview = lazy(() =>
  import("../../../pages/Courses/components/UserCourse/CoursePreview")
);
const AllUserCourses = lazy(() =>
  import("../../../pages/Courses/components/UserCourse/AllUserCourses")
);
const CourseUserWelcomDashboard = lazy(() =>
  import("../../../pages/Courses/components/UserCourse/CourseUserWelcomDashboard")
);
const CourseUserList = lazy(() =>
  import("../../../pages/Courses/components/CourseStudio/CourseUserList")
);
// Events
const Events = lazy(() => import("../../../pages/Events/components/Events"));
const CreateEventPage = lazy(() =>
  import("../../../pages/Events/components/CreateEvent")
);
const EventDashboard = lazy(() =>
  import("../../../pages/Events/components/EventDashboard/EventDashboard")
);
const EventsList = lazy(() =>
  import("../../../pages/Events/components/EventsList")
);
const Settings = lazy(() =>
  import("../../../pages/Events/components/Settings")
);
const Insights = lazy(() =>
  import("../../../pages/Events/components/Insights")
);
const Contributors = lazy(() =>
  import("../../../pages/Events/components/Contributors")
);
// Speakers
const Speakers = lazy(() =>
  import("../../../pages/Speakers/components/Speakers")
);
const AddSpeaker = lazy(() =>
  import("../../../pages/Speakers/components/AddSpeaker")
);
// Sponsors
const Sponsors = lazy(() =>
  import("../../../pages/Sponsors/components/Sponsors")
);
const AddSponsor = lazy(() =>
  import("../../../pages/Sponsors/components/AddSponsor")
);
// Appointments
const AppointmentEvents = lazy(() =>
  import(
    "../../../pages/Appointment/components/AppointmentEvents/AppointmentEvents"
  )
);
const CreateAppointmentEvent = lazy(() =>
  import(
    "../../../pages/Appointment/components/CreateAppointmentEvent/CreateAppointmentEvent"
  )
);
const AppointmentEventPage = lazy(() =>
  import("../../../pages/Appointment/components/AppointmentEventPage")
);
const UserAppointmentPage = lazy(() =>
  import("../../../pages/Appointment/components/UserAppointmentPage")
);
const ManageAppointmentEvent = lazy(() =>
  import(
    "../../../pages/Appointment/components/CreateAppointmentEvent/ManageAppointmentEvent"
  )
);
// Blogs
const BlogsDashboard = lazy(() =>
  import("../../../pages/Blogs/components/BlogsDashboard")
);
const CreateBlog = lazy(() =>
  import("../../../pages/Blogs/components/CreateBlog")
);

// Workshop
const WorkshopPage = lazy(() => import("../../../pages/Workshop/WorkshopPage"));
const WorkshopLandingPage = lazy(() =>
  import("../../../pages/Workshop/WorkshopLandingPage")
);

// Web Page Content
const WebPageContent = lazy(() =>
  import("../../../pages/WebPageContent/components/WebPage/WebPageContent")
);

// Login Page
const LoginPage = lazy(() =>
  import("../../../pages/Authentication/components/LoginPage")
);

// Users List
const UsersList = lazy(() =>
  import("../../../pages/Authentication/components/User/UsersList"))
const CreateReview = lazy(() =>
  import("../../../pages/Authentication/components/User/Review/CreateReviewForm"))
const UsersReviewList = lazy(() =>
  import("../../../pages/Authentication/components/User/Review/UsersReviewList"))
// Page not Found
const PageNotFound = lazy(() =>
  import("../../../components/sharedComponents/PageNotFound")
);

// Wittyhacks
const Wittyhacks = lazy(() =>
  import("../../../pages/Wittyhacks/Wittyhacks")
);

//Roles
const Role = lazy(() =>
  import("../../../pages/UserRole/Components/Role/RoleList/RoleList"))
const ViewRole = lazy(() =>
  import("../../../pages/UserRole/Components/ViewRole/ViewRole"))
// Days Code
const General = lazy(() => import('../../../pages/DaysCode/components/Settings/General/General'))
const ProblemsList = lazy(() => import('../../../pages/DaysCode/components/Settings/Problems/ProblemsList'))
const AddProblem = lazy(() => import('../../../pages/DaysCode/components/Settings/Problems/AddProblem'))
const ProblemPreview = lazy(() => import('../../../pages/DaysCode/components/Settings/Problems/ProblemPreview'))
const SubmissionsList = lazy(() => import('../../../pages/DaysCode/components/Settings/Submission/SubmissionsList'))
const DaysUsersList = lazy(() => import('../../../pages/DaysCode/components/Settings/UsersList'))
const Notification = lazy(() => import('../../../pages/DaysCode/components/Settings/Notification'))
const AddLesson = lazy(() => import('../../../pages/DaysCode/components/Settings/Lesson/AddLesson'))
const LessonsList = lazy(() => import('../../../pages/DaysCode/components/Settings/Lesson/LessonsList'))
const QuizChallengesList = lazy(() => import('../../../pages/DaysCode/components/Settings/Challenge/QuizChallengesList'))
const AddQuizChallenge = lazy(() => import('../../../pages/DaysCode/components/Settings/Challenge/AddQuizChallenge'))
const AddUserAttendance = lazy(() => import('../../../pages/DaysCode/components/Settings/UserAttendance/AddUserAttendance'))
const UserAttendanceList = lazy(() => import('../../../pages/DaysCode/components/Settings/UserAttendance/UserAttendanceList'))
const FeedBacks = lazy(() => import('../../../pages/DaysCode/components/Settings/FeedBacks'))
const DaysCodeBatches = lazy(() => import('../../../pages/DaysCode/components/Settings/DaysCodeBatch/DaysCodeBatches'))
const DaysCodeBatchPage = lazy(() => import('../../../pages/DaysCode/components/Settings/DaysCodeBatch/DaysCodeBatchPage'))
const CreateMentor = lazy(() => import('../../../pages/DaysCode/components/Settings/Mentor/CreateMentor'))
const MentorsList = lazy(() => import('../../../pages/DaysCode/components/Settings/Mentor/MentorsList'))
const UsersProgress = lazy(() => import('../../../pages/DaysCode/components/Settings/Submission/UsersProgress'))
const CreateCodeBatch = lazy(() => import("../../../pages/DaysCode/components/Settings/DaysCodeBatch/CreateCodeBatch"))
const CodeClassesList = lazy(() => import("../../../pages/DaysCode/components/Settings/CodeClass/CodeClassesList"))
const CreateCodeClass = lazy(() => import("../../../pages/DaysCode/components/Settings/CodeClass/CreateCodeClass"))

// Club
const ClupApplicationView = lazy(() => import("../../../pages/Club/components/ClupApplicationView"))
const Applications = lazy(() => import("../../../pages/Club/components/Applications"))
// QuizTest
const QuizTestDashboard = lazy(() => import('../../../pages/QuizTest/component/QuizTest/QuizTestDashbaord'))
const QuizTestsList = lazy(() => import('../../../pages/QuizTest/component/QuizTest/contributor_home/QuizTestsList'))
const QuizBoardHome = lazy(() => import('../../../pages/QuizTest/component/Quizboard'))
const ReportReview = lazy(() => import('../../../pages/QuizTest/component/QuizTest/QuizTestDashbaord/ReportReview/ReportReview'))
const CreateQuizSettings = lazy(() => import('../../../pages/QuizTest/component/QuizTest/QuizTestDashbaord/settings'))

const AppRouters = () => {
  return (
    <>
      <Suspense fallback={<h1>Loading...</h1>}>
        <Routes>
          {/* Private Routes */}
          <Route element={<PrivateRoute />}>
            {/* Navigation Routing */}
            <Route exact path="/" element={<Creator />} errorElement={<ErrorPage />} />
            {/* Courses */}
            <Route path="/course" element={<CourseDashboard />} />
            <Route path="/course/new" element={<CreateCourse />} />
            <Route path="/course/:id/edit" element={<CourseStudio type="course" />} />
            <Route path="/course/:id/users" element={<CourseUserList />} />
            <Route path="/courses/list" element={<CoursesList />} />
            <Route path="/course/students" element={<CourseStudents />} />
            <Route path="/course/enrolls" element={<CourseEntrollments />} />
            <Route path="/course/settings" element={<CourseSettings />} />
            <Route path="/blog/preview/:id" element={<LessonPreview />} />
            <Route path="/courses" element={<AllUserCourses />} />
            <Route path="/courses/user" element={<CourseUserWelcomDashboard />} />
            <Route path="/courses/my" element={<UserCourseDashboard />} />
            <Route path="/course/:id" element={<CourseDetails />} />
            <Route path="/courses/preview/:id" element={<CoursePreview />} />

            {/* Events */}
            <Route exact path="/events" element={<Events />} />
            <Route exact path="/create_event" element={<CreateEventPage />} />
            <Route exact path="/event/dashboard/:id" element={<EventDashboard />} />
            <Route exact path="/event/dashboard" element={<EventsList />} />
            <Route exact path="/event/settings" element={<Settings />} />
            <Route exact path="/event/insights" element={<Insights />} />
            <Route exact path="/event/contributors" element={<Contributors />} />
            {/* Appointments */}
            <Route exact path="/appointments" element={<AppointmentEvents />} />
            <Route exact path="/appointment/:id" element={<AppointmentEventPage />} />
            <Route exact path="AddQuizChallenge/appointment/event/:id" element={<CreateAppointmentEvent />} />
            <Route exact path="/appointment/book/:id" element={<UserAppointmentPage />} />
            <Route exact path="/appointment/event/manage/:id" element={<ManageAppointmentEvent />} />
            {/* Speakers */}
            <Route exact path="/speakers" element={<Speakers />} />
            <Route exact path="/speaker/:id" element={<AddSpeaker />} />
            {/* Sponsors */}
            <Route exact path="/sponsors" element={<Sponsors />} />
            <Route exact path="/sponsor/:id" element={<AddSponsor />} />
            {/* Blogs */}
            <Route exact path="/blogs" element={<BlogsDashboard />} />
            <Route exact path="/blog/create" element={<CreateBlog />} />
            <Route exact path="/blog/:blogId/manage" element={<CourseStudio type="blog" />} />

            {/* Workshop */}
            <Route exact path="/academy/workshop/:id" element={<WorkshopPage />} />
            <Route exact path="/academy/workshop" element={<WorkshopLandingPage />} />

            {/* Web Page Content */}
            <Route exact path="/webpagecontent" element={<WebPageContent />} />

            {/* Dayscode */}
            <Route
              path="/admin/days_code/problem/preview/:id"
              element={<ProblemPreview />}
            />
            <Route
              path="/admin/days_code/problem/:id"
              element={<AddProblem />}
            />
            <Route
              path="/admin/days_code/problems"
              element={<ProblemsList />}
            />
            <Route
              path="/admin/days_code/general"
              element={<General />}
            />
            <Route
              path="/admin/days_code/submissions"
              element={<SubmissionsList />}
            />
            <Route
              path="/admin/days_code/users"
              element={<DaysUsersList />}
            />
            <Route
              path="/admin/days_code/notification"
              element={<Notification />}
            />
            <Route
              path="/admin/days_code/lesson/:id"
              element={<AddLesson />}
            />
            <Route
              path="/admin/days_code/lessons"
              element={<LessonsList />}
            />
            <Route
              path="/admin/days_code/quiz_challenge/:id"
              element={<AddQuizChallenge />}
            />
            <Route
              path="/admin/days_code/quiz_challenges"
              element={<QuizChallengesList />}
            />
            <Route
              path="/admin/days_code/user_attendance_list"
              element={<UserAttendanceList />}
            />
            <Route
              path="/admin/days_code/session_attendance"
              element={<AddUserAttendance />}
            />
            <Route
              path="/admin/days_code/feedbacks"
              element={<FeedBacks />}
            />
            <Route
              path="/admin/days_code/batch_users"
              element={<UsersProgress />}
            />
            <Route
              path="/admin/days_code/batch/:id"
              element={<CreateCodeBatch />}
            />
            <Route
              path="/admin/days_code/batch_dashboard/:id"
              element={<DaysCodeBatchPage />}
            />
            <Route
              path="/admin/days_code/batches"
              element={<DaysCodeBatches />}
            />
            <Route
              path="/admin/days_code/mentor/:id"
              element={<CreateMentor />}
            />
            <Route
              path="/admin/days_code/mentors"
              element={<MentorsList />}
            />
            <Route
              path="/admin/days_code/codeclass"
              element={<CodeClassesList />}
            />
            <Route
              path="/admin/days_code/codeclass/:id"
              element={<CreateCodeClass />}
            />
            {/* Web Page Content */}
            <Route exact path="/webpagecontent" element={<WebPageContent />} />

            {/* wittyhacks */}
            <Route exact path="/wittyhacks" element={<Wittyhacks />} />

            <Route exact path="/roles" element={<RoleList />} />
            <Route exact path="/role/:id" element={<ViewRole />} />
            <Route exact path="/permissions" element={<PermissionsList />} />

            {/* Users List */}
            <Route exact path="/users" element={<UsersList />} />
            <Route exact path="/users/review" element={<UsersReviewList />} />
            <Route exact path='/users/review/:id' element={<CreateReview />} />

            {/* Club Routes */}
            <Route
              exact
              path="/admin/club/application"
              element={<Applications />}
            />
            <Route
              exact
              path="/admin/club/application/:id"
              element={<ClupApplicationView />}
            />

            {/* Quiz Test */}
            <Route exact path="/quiztest" element={<QuizBoardHome />} />
            <Route exact path="/quiz/tests" element={<QuizTestsList />} />
            <Route exact path="/quiz/test/:id" element={<QuizTestDashboard />} />
            <Route exact path="/quiz/test/review/report/:id" element={<ReportReview />} />
            {/* <Route path="/contributor_home" element={<ContributorHome />} /> */}
            {/* <Route exact path="/create_quiz_test_question" element={<CreateQuestion />} /> */}
            <Route exact path="/create_quiz_test_settings" element={<CreateQuizSettings />} />
            {/* -------------------- */}
            {/* <Route exact path="/social_media" element={<SocialMediaLinks />} />
            <Route exact path="/speaker_form" element={<SpeakerRegistration />} /> */}
          </Route>

          {/* Hello Page */}
          <Route exact path="/hello" element={<HelloPage />} />
          {/* Login Page */}
          <Route exact path="/login" element={<LoginPage />} />
          {/* Page Not Found / 404 */}
          <Route exact path="*" element={<PageNotFound />} />



        </Routes>
      </Suspense>
    </>
  );
};

// const mapDispatchToProps = {
//   setLocalUser,
//   getDaysUserReg,
// };

// const mapStateToProps = ({ app, user, dayscode }) => ({
//   currentUser: auth.currentUser ? auth.currentUser : {},
//   daysUserDetails: dayscode.daysUserDetails,
//   daysUserDetailsLoading: dayscode.daysUserDetailsLoading,
// });

export default connect(null, null)(AppRouters);
